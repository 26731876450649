import { toJS } from 'mobx'
import { message } from 'antd'
import { makeAutoObservable } from 'mobx'
import Message from '@/utils/message'
import { mobxToJavaScript, scrollAnimation, parseQueryString } from '@/utils/common'
import httpClient from '@/utils/httpClient'
import { previewH5Url } from '@/config'
import { getApi } from '@/utils/mapApi'
import Api from '@/config/api'
import { getFakeTotal } from '@/utils/common'

const { genre = 'site', origin } = parseQueryString() as any

const { fitment, common } = Api

class FitmentStore {
  [key: string]: any

  fitmentType = ''

  constructor() {
    makeAutoObservable(this)
    this.messager = null
  }

  loadFitmentType = async (tinyId: string) => {
    const page = this.pageList.find((item: any) => item.id == tinyId) as any
    if (!page) {
      return
    }
    if ([0, 1].includes(page.useFlag)) {
      // 选品
      this.fitmentType = 'selection'
    } else if ([3].includes(page.useFlag)) {
      // 名片
      this.fitmentType = 'business_card'
    }
    // 销毁消息
    this.messager = null;
  }

  // 更新state
  updateState = (params: any) => {
    for (let [key, value] of Object.entries(params)) {
      this[key] = value
    }
  }

  getMessager = () => {
    if (!this.messager) {
      try {
        const iframeDom = window.document.querySelector('#page-view__iframe') as any
        const messager = new Message(iframeDom.contentWindow, previewH5Url)
        this.messager = messager
        return messager
      } catch (e) {
        console.warn(e)
      }
    }
    return this.messager
  }

  isChange = false

  iframeLoad = false
  // activekey: string = "page__common";

  activekey: string = 'component_common'

  pageConfig = {
    pageBackground: '#f9f9f9',
  }

  pageName = ''

  shareConfig: any = {}

  templateList: any = []

  templateIds: any = []

  // 商品禁用逻辑
  goods_disalbe: boolean = false

  handleChangeIframeLoad = (iframeLoad: boolean) => {
    this.iframeLoad = iframeLoad
  }

  handleInitTemplate = (templateList: any) => {
    try {
      this.getMessager().emit('onInitTemplate', mobxToJavaScript(templateList))
    } catch (e) {
      console.warn(e)
    }
  }

  handleInitPageConfig = (pageConfig: any) => {
    try {
      this.getMessager().emit('onInitPageConfig', mobxToJavaScript(pageConfig))
    } catch (e) {
      console.warn(e)
    }
  }

  handleInitInfo = (info: any) => {
    try {
      this.getMessager().emit('onInitInfo', mobxToJavaScript(info))
    } catch (e) {
      console.warn(e)
    }
  }

  pageViewScrollTop = () => {
    const dom = document.querySelector('.page-view-components') as any
    const currentY = dom.scrollTop
    scrollAnimation(dom, currentY, dom.scrollHeight)
  }

  editScrollTop = () => {
    const dom = document.querySelector('.page-editor-wrap-components') as any
    const currentY = dom.scrollTop
    scrollAnimation(dom, currentY, 0)
  }

  handleAddtemplate = (newTemplate: any) => {
    try {
      if (!this.iframeLoad) {
        return
      }
      const newTemplateList = [...this.templateList, newTemplate]
      this.templateList = newTemplateList
      this.templateIds = newTemplateList && newTemplateList.map((item) => item.id)
      this.getMessager().emit('onAddtemplate', mobxToJavaScript(newTemplate))
      this.isChange = true
      // this.pageViewScrollTop();
    } catch (e) {
      console.warn(e)
    }
  }

  handleChangeActiveKey = (activekey: string) => {
    try {
      if (!this.iframeLoad) {
        return
      }
      this.activekey = activekey
      this.getMessager().emit('onChangeActive', activekey)
      this.editScrollTop()
    } catch (e) { }
  }

  handleChangeEditData = (obj: any) => {
    try {
      if (!this.iframeLoad) {
        return
      }
      let templateList = [...this.templateList]
      let thisIndex = -1
      templateList &&
        templateList.forEach((item, index) => {
          if (item.id === this.activekey) {
            thisIndex = index
          }
        })
      if (thisIndex !== -1) {
        templateList[thisIndex] = {
          ...templateList[thisIndex],
          data: { ...templateList[thisIndex].data, ...obj },
        }
        this.templateList = templateList

        this.getMessager().emit(
          'onChangeTemplateData',
          mobxToJavaScript({
            id: templateList[thisIndex].id,
            data: obj,
            index: thisIndex,
          })
        )
        this.isChange = true
      }
    } catch (e) {
      console.warn(e)
    }
  }

  handleChangePageConfig = (obj = {}) => {
    const pageConfig = { ...this.pageConfig, ...obj }
    this.pageConfig = pageConfig
    this.getMessager().emit('onChangePageConfig', mobxToJavaScript(pageConfig))
    this.isChange = true
  }

  onH5ChangeActive = () => {
    try {
      if (!this.iframeLoad) {
        return
      }
      this.getMessager().on('onH5ChangeActive', (activekey: any) => {
        this.activekey = activekey
        this.editScrollTop()
      })
    } catch (e) {
      console.warn(e)
    }
  }

  onH5Delete = () => {
    try {
      if (!this.iframeLoad) {
        return
      }
      this.getMessager().on('onH5Delete', (id: any) => {
        const newTemplateList =
          this.templateList && this.templateList.filter((item: any) => item.id !== id)
        this.templateList = newTemplateList
        this.getMessager().emit('onInitTemplate', mobxToJavaScript(newTemplateList))
        this.isChange = true
      })
    } catch (e) {
      console.warn(e)
    }
  }

  // 电梯组件高度
  elevatorHeight: any = {}

  onElevatorHeightChange = () => {
    try {
      if (!this.iframeLoad) {
        return
      }
      this.getMessager().on('onElevatorHeightChange', (obj: any) => {
        this.elevatorHeight[obj.id] = obj.height
        // console.log(obj)
        // const templateList = [...this.templateList]
        // const idx = templateList.findIndex((item: any) => item.id === obj.id)
        // if(idx > -1) {
        //   templateList[idx].data.height = obj.height
        // }
        // this.templateList = templateList
      })
    } catch (e) {
      console.warn(e)
    }
  }

  onScroolThisComponent = (id: any) => {
    this.getMessager().emit('onScroolThisComponent', id)
  }

  // 获取截图
  getPrintscreen = async (params: any = {}) => {
    console.log(toJS(this.templateList))
    this.getMessager().emit('getPrintscreen', {
      token: window.localStorage.getItem('token'),
      ...params,
    })
  }

  saveLoading = false
  pubLoading = false
  prevLoading = false

  handelPublish = async (parmas: any = {}) => {
    const { type, url } = parmas
    const { tinyId } = parseQueryString() as any

    let fitmentParams: any = {
      release: type === 'line',
    }

    if (url) {
      fitmentParams.coverImage = url
    }

    const pathnames = window.location.pathname.split('/')
    const editId = pathnames[pathnames.length - 1]

    if (genre === 'site') {
      fitmentParams.siteDecorationId = this.pageValue
      fitmentParams.siteId = editId
      // fitmentParams.
    } else {
      fitmentParams.templateId = editId
      fitmentParams.templateDecorationId = this.pageValue
    }

    const result: any = await this.updateFitmentContent(fitmentParams)

    if (result && result.success) {
      this.isChange = false

      let previewUrl = `/preview/${editId}?origin=${origin}&genre=${genre}`
      let successText = '发布成功'

      if (['preview', 'save'].includes(type)) {
        successText = '保存成功'
      }

      if (genre === 'site') {
        previewUrl = `/saas/preview/${editId}?origin=${origin}&genre=${genre}`
      }

      if (tinyId) {
        previewUrl += `&tinyId=${tinyId}`
      }

      message.success(successText)

      if (previewUrl && type !== 'save') {
        window.location.href = previewUrl
      }
    }

    this.saveLoading = false
    this.pubLoading = false
    this.prevLoading = false
  }

  onChangePrintscreen = () => {
    this.getMessager().on('onChangePrintscreen', async (params: any) => {
      this.handelPublish(params)
    })
  }
  destroyMessage = () => {
    this.getMessager() && this.getMessager().destroy()
  }

  onH5SortUpdate = () => {
    try {
      if (!this.iframeLoad) {
        return
      }
      this.getMessager().on('onH5SortUpdate', (templateIds: any) => {
        this.templateIds = templateIds
        this.templateList =
          templateIds &&
          templateIds.map((item: any) => {
            return this.templateList && this.templateList.filter((ft: any) => ft.id === item)[0]
          })
      })
    } catch (e) {
      console.warn(e)
    }
  }

  /**
   *  获取模版内容
   */

  fitmentId = null
  fitmentLoading = true
  getFitmentContent = async (id: any) => {
    try {
      this.fitmentLoading = true
      const data = (await getApi('get_fitment_content', id)) as any
      this.fitmentId = data.id

      const formatData = JSON.parse(data.content)
      const formatShare = JSON.parse(data.savedShare)

      if (formatData) {
        this.templateList = formatData.templateList || []
        this.pageConfig = formatData.pageConfig || {}
        this.handleInitTemplate(formatData.templateList)
        this.handleInitPageConfig(formatData.pageConfig)
      } else {
        this.templateList = []
        this.pageConfig = { pageBackground: '#f9f9f9' }
        this.handleInitTemplate([])
        this.handleInitPageConfig(this.pageConfig)
      }

      if (formatShare) {
        this.shareConfig = formatShare
      } else {
        this.shareConfig = {}
      }

      this.pageName = data.savedPageName

      this.fitmentLoading = false
    } catch (e) {
      this.fitmentLoading = false
    }
  }

  /**
   *  更新模版内容
   */

  // fitmentLoading = false

  updateFitmentContent = async (params: any) => {
    if (!this.pageName) {
      message.error('页面名称不可为空～')
      return
    }

    if (!this.templateList.length) {
      message.error('装修内容不可为空～')
      return
    }

    // 地图的定位地址必填
    for (let i = 0; i < this.templateList.length; i++) {
      if (this.templateList[i].componentId === 'view__map') {
        if (this.templateList[i].data.addressList?.length) {
          for (let j = 0; j < this.templateList[i].data.addressList.length; j++) {
            if (this.templateList[i].data.addressList[j].markerPosition?.length !== 2) {
              message.error('公司定位地址存在空值～')
              return
            }
          }
        }
      }
    }

    // 价格组件校验
    for (let i = 0; i < this.templateList.length; i++) {
      if (this.templateList[i].componentId === 'view__price') {
        const data = this.templateList[i].data
        if (data.titleShow && !data.title) {
          message.error('请设置价格组件标题～')
          return
        }
        if (data.priceList.length === 0) {
          message.error('价格组件必须设置价格区间～')
          return
        }
      }
    }

    // 电梯组件校验
    for (let i = 0; i < this.templateList.length; i++) {
      if (this.templateList[i].componentId === 'view__elevator') {
        const data = this.templateList[i].data
        if (data.list.length === 0) {
          message.error('请设置电梯组件导航～')
          return
        }
        for (let i1 = 0; i1 < data.list.length; i1++) {
          const item = data.list[i1]
          if (!item.img && data.blockTempKey === '0') {
            message.error('请设置电梯组件导航图片～')
            return
          }
          if (!item.to) {
            message.error('请设置电梯组件定位组件～')
            return
          }
          const idx = this.templateList.findIndex((t: any) => t.id === item.to)
          if (idx < i) {
            message.error('电梯导航组件位置往下发生变化，请重新选择定位组件～')
            return
          }
        }
      }
    }

    // 运营端模板增加逻辑
    if (genre === 'template') {
      const pathnames = window.location.pathname.split('/')
      const editId = pathnames[pathnames.length - 1]
      const params: any = {
        templateId: Number(editId),
        templateDecorationId: Number(this.pageValue),
      }
      const profitInfoList: any[] = []
      const map = new Map([
        ['classify', 2],
        ['brand', 1],
      ])
      this.templateList.forEach((item: any) => {
        if (item.componentId === 'view__image_ad') {
          const list = item.data.imageList
          if (list && list.length) {
            list.forEach((item: any) => {
              if (['brand', 'classify'].includes(item.linkType)) {
                profitInfoList.push({
                  type: map.get(item.linkType),
                  ruleId: item.link,
                  minProfit: Number(item.profit[0]),
                  maxProfit: Number(item.profit[1]),
                })
              }
              if (item.position && item.position.length) {
                item.position.forEach((item: any) => {
                  if (['brand', 'classify'].includes(item.linkType)) {
                    profitInfoList.push({
                      type: map.get(item.linkType),
                      ruleId: item.link,
                      minProfit: Number(item.profit[0]),
                      maxProfit: Number(item.profit[1]),
                    })
                  }
                })
              }
            })
          }
        }
        if (item.componentId === 'view__image_text_navigation') {
          const list = item.data.navList
          if (list && list.length) {
            list.forEach((item: any) => {
              if (['brand', 'classify'].includes(item.linkType)) {
                profitInfoList.push({
                  type: map.get(item.linkType),
                  ruleId: item.link,
                  minProfit: Number(item.profit[0]),
                  maxProfit: Number(item.profit[1]),
                })
              }
            })
          }
        }
        if (item.componentId === 'view__title_text') {
          const linkObj = item.data.linkObj
          if (linkObj && ['brand', 'classify'].includes(linkObj.linkType)) {
            profitInfoList.push({
              type: map.get(linkObj.linkType),
              ruleId: linkObj.link,
              minProfit: Number(linkObj.profit[0]),
              maxProfit: Number(linkObj.profit[1]),
            })
          }
        }
      })

      params.profitInfoList = profitInfoList
      // console.log(params)
      // return
      if (params.profitInfoList.length) {
        try {
          httpClient.post(fitment.addBrandOrCategory, { ...params }) as any;
        } catch (e) {
          console.log(e)
        }
      }
    }

    // return

    const templateList = [...this.templateList]
    // 处理电梯组件高度
    const keys = Object.keys(this.elevatorHeight)
    if (keys && keys.length) {
      keys.forEach((key: string) => {
        const idx = templateList.findIndex((item: any) => item.id === key)
        if (idx > -1) {
          templateList[idx].data.height = this.elevatorHeight[key]
        }
      })
    }

    // this.templateList = templateList

    // console.log(toJS(templateList))

    // return

    try {
      // this.fitmentLoading = true;
      const formatParams = {
        savedContent: JSON.stringify({
          templateList: templateList,
          pageConfig: this.pageConfig,
        }),
        savedPageName: this.pageName,
        savedShare: JSON.stringify(this.shareConfig),
        // coverImage: 'https://oss.elebuys.com/tmpdir/202012031536200006550140.jpg',
        ...params,
      }
      // console.log(formatParams)
      // return
      const result = (await getApi('updata_fitment_content', formatParams)) as any
      this.fitmentLoading = false
      return result
    } catch (e) {
      this.fitmentLoading = false
      this.saveLoading = false
      this.pubLoading = false
      this.prevLoading = false
    }
  }

  // 获取商品是否需要禁用
  getGoodsToSite = async (params: any) => {
    try {
      const { data } = (await httpClient.get(fitment.get_pick_goods_to_site_status, {
        params,
      })) as any
      if (Number(data.isComplete) === 0) {
        this.goods_disalbe = true
        return
      }
      this.goods_disalbe = false
    } catch (e) {
      this.goods_disalbe = false
    }
  }

  /**
   *  获取标签
   */

  tagLoading = false
  tagList: any = []

  formatChild = (children: any) => {
    if (!children) {
      return []
    }
    return children.map((childItem: any) => ({
      id: childItem.goodsTagId,
      tagCategoryId: childItem.goodsTagId,
      tagName: childItem.tagName,
    }))
  }

  getTabg = async (params: any = {}) => {
    try {
      this.tagLoading = true
      const { data } = (await httpClient.post(common.get_common_tag, params)) as any
      this.tagLoading = false

      if (data) {
        let localTagList: any = []
        data &&
          data.forEach((item: any) => {
            if (item.children) {
              localTagList.push({
                tagLabel: item.parentTagName,
                tagList: [
                  { id: 'all', tagCategoryId: 'all', tagName: '全部' },
                  ...this.formatChild(item.children),
                ],
              })
            }
          })
        this.tagList = localTagList
      }

      return data
    } catch (e) { }
  }

  /**
   *  获取挑选商品列表
   */

  selectGoodsList: any = []
  selectGoodsLoading = false
  selectGoodsPageSize = 10
  selectGoodsTotal = 0
  realSelectGoodsTotal = 0
  selectGoodsPageInfo = {
    pageNo: 1,
    pageSize: this.pageSize,
  }

  stockLoading = false
  getSelectGoodsList = async (params: any, isChooseMore?: boolean) => {
    try {
      this.selectGoodsLoading = true
      let address = params.address ?? [15, 1213]
      params.poolSaasStatus = 10
      delete params.address

      const { data } = (await getApi(
        isChooseMore ? 'get_select_goods_from_mall' : 'getSelectGoods',
        params
      )) as any
      this.selectGoodsLoading = false

      this.selectGoodsList =
        data.list &&
        data.list.map((item: any, index: any) => ({
          ...item,
          key: index,
          groupPriceDispaly: true,
        }))

      const hasJDGoods = data.list.filter((item: any) => item.thirdType === 10)

      this.selectGoodsTotal = getFakeTotal(params.pageSize, data.total)
      this.realSelectGoodsTotal = data.total
      this.selectGoodsPageInfo = {
        pageNo: data.page,
        pageSize: data.pageSize,
      }

      // 如果是jd商品去获取下库存
      if (
        (params.thirdType === 10 || hasJDGoods.length) &&
        data.list &&
        data.list.length &&
        address
      ) {
        this.getJdStock({ provinceId: address[0], cityId: address[1] })
      }
    } catch (e) {
      this.selectGoodsLoading = false
      this.selectGoodsTotal = 0
      this.selectGoodsPageInfo = { pageNo: 1, pageSize: 10 }
      this.selectGoodsList = []
      this.selectGoodsPageSize = 10
    }
  }

  getJdStock = async (parmas = {}) => {
    if (this.selectGoodsList && this.selectGoodsList.length) {
      this.stockLoading = true

      const filterThirdList = this.selectGoodsList.filter((item: any) => item.thirdSku !== null)

      const idList = filterThirdList.map((item: any) => ({
        num: 1,
        goodsId: item.goodsId,
      }))
      const { provinceId, cityId } = this.siteInfo
      const { data: stockList } = await httpClient.post(common.get_jd_goods_stock, {
        cityId,
        provinceId,
        countyId: 0,
        streetId: 0,
        idList,
        ...parmas,
      })

      this.selectGoodsList =
        this.selectGoodsList &&
        this.selectGoodsList.map((item: any) => {
          const stockData =
            (stockList &&
              stockList.filter((stockItem: any) => stockItem.thirdSku === item.thirdSku)[0]) ||
            {}
          return {
            ...item,
            ...stockData,
          }
        })
      this.stockLoading = false
    }
  }

  // 挑选商品到模版
  addGoodsToTemplate = async (params: any = {}) => {
    return httpClient.post(fitment.add_goods_to_template, params) as any
  }

  // 查询装修商品
  fitmentGoodsLoading = false

  getFitmentGoods = async (params: any = {}) => {
    return httpClient.post(common.get_select_goods_list, params) as any
  }

  // 站点/模板专题挑选分页
  getSelectSpecial = async (params: any = {}) => {
    return getApi('get_select_special_list', params) as any
  }

  // 商品分组
  getSelectGroup = async (params: any = {}) => {
    return httpClient.post(common.get_site_goods_group, params) as any
  }

  // 获取商品分组商品信息
  getSiteGroupGoodsList = async (params: any = {}) => {
    return httpClient.post(fitment.get_group_goods_list, params) as any
  }

  // 挑选专题到模版
  addSpecialToTemplate = async (params: any = {}) => {
    return httpClient.post(fitment.add_special_to_template, params) as any
  }

  // 获取父专题信息
  getSpecialParentInfo = async (params: any = {}) => {
    return httpClient.get(common.get_special_parent_info, { params }) as any
  }

  // 获取专题商品信息
  getSpecialGoodsList = async (params: any = {}) => {
    return httpClient.post(fitment.get_special_goods_list, params) as any
  }

  // 获取站点专题商品信息
  getSiteSpecialGoodsList = async (params: any = {}) => {
    params.siteId = undefined
    return httpClient.post(fitment.get_site_special_goods_list, params) as any
  }

  // 专题改价
  addSpecialGoodsToSite = async (params: any = {}) => {
    return httpClient.post(fitment.add_goods_to_site, params) as any
  }

  // 改价
  addGoodsToSite = async (params: any = {}) => {
    return httpClient.post(fitment.change_price_pick_goods, params) as any
  }

  siteInfo: any = {}

  // 站点信息获取
  getFitmentInfo = async (params: any = {}) => {
    return getApi('get_fitment_info', params)
  }

  // 添加专题到站点
  addSpecialToSite = async (params: any = {}) => {
    return httpClient.post(fitment.add_special_to_site, params) as any
  }

  getShare = async (params: any = {}) => {
    const { data } = await getApi('get_share', params)
    if (data) {
      this.shareConfig = data
    }
    return data
  }

  upDateShareConfig = async (params: any = {}) => {
    return getApi('update_share_config', params)
  }

  pageList = []
  pageValue = ''

  getFitmentTinyList = async (params: any = {}) => {
    const { tinyId } = parseQueryString() as any
    const result = await getApi('get_fitment_tiny_list', params)
    const { data } = result
    this.pageList = data?.filter((item: any) => [0, 1, 3].includes(item.useFlag))

    data?.forEach((item: any) => {
      if (tinyId) {
        if (Number(item.id) === Number(tinyId)) this.pageValue = item.id
      } else {
        if (item.isHomepage) this.pageValue = item.id
      }
    })

    if (data && data[0]) {
      if (data[0].id && !this.pageValue) {
        this.pageValue = data[0].id
      }
    }

    return result
  }

  addTempalteBrandGoods = async (params: any) => {
    const result = await httpClient.post(fitment.add_tempalte_brand_goods, params)
  }

  tempalteBrand: any = []
  getTempalteBrandGoods = async (params: any) => {
    // const { data } = await httpClient.get(fitment.get_tempalte_brand_goods, { params });
    // this.tempalteBrand = data;
  }

  addTempalteCategoryGoods = async (params: any) => {
    return httpClient.post(fitment.add_tempalte_category_goods, params)
  }

  tempalteCategory: any = []
  getTempalteCategoryGoods = async (params: any) => {
    // const { data } = await httpClient.get(fitment.get_tempalte_category_goods, { params });
    // this.tempalteCategory = data;
  }

  // 添加商品之前 - 亏本校验
  siteCheckBeforeGoods = async (params: any) => {
    return httpClient.post(fitment.site_check_before_goods, params)
  }

  // 检查是否符合保底利润率要求
  siteCheckBeforeSpecial = async (params: any) => {
    return httpClient.post(fitment.site_check_before_special, params)
  }

  // 专题亏本校验
  addTopicCheckLoss = async (params: any) => {
    return httpClient.post(fitment.add_topic_check_loss, params)
  }

  // 获取专题商品id
  getSpecialGoodsIds = async (params: any) => {
    return httpClient.post(fitment.get_special_goods_ids, params)
  }

  // 获取商品标签列表
  getGoodsAllFlagList = async () => {
    return await httpClient.post(fitment.get_goods_all_flag_list);
    // this.allFlagList = data;
  };
}

export default new FitmentStore()
