import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { Watermark } from 'antd';
import { ProLayout, GridContent } from '@ant-design/pro-components';
import theme from '@fle-ui/plus-theme';
import commonStore, { type UserInfo } from '@/store/common';

const { proTheme } = theme;

interface WatermarkContentProps {
  content?: string | string[];
  children?: React.ReactNode;
}

function WatermarkContent({ content, children }: WatermarkContentProps) {
  return useMemo(() => {
    const tokens = localStorage.getItem('token')?.split('-');

    const isCarpuionAdmin = tokens?.length ? tokens[tokens.length - 1] === '100002' : false;

    if (isCarpuionAdmin) {
      return (
        <Watermark
          style={{ width: '100%', height: '100vh' }}
          content={content}
          gap={[100, 100]}
          offset={[60, 40]}
          rotate={-15}
          font={{
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.05)',
            fontWeight: 400,
          }}>
          {children}
        </Watermark>
      );
    }

    return children;
  }, [content, children]);
}

const Layout: React.FC = () => {
  const location = useLocation();

  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    commonStore.getUserInfo().then(setUserInfo);
  }, []);

  return (
    <WatermarkContent content={userInfo ? ['', `${userInfo.name}`, `${userInfo.departmentName}`, ''] : ''}>
      <ProLayout pure token={proTheme} defaultCollapsed breakpoint={false} pageTitleRender={false} location={location}>
        <GridContent>
          <Outlet />
        </GridContent>
      </ProLayout>
    </WatermarkContent>
  );
};

export default Layout;
